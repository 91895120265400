@import "../../utils/colors.scss";

.stuff {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            font-size: 24px;
            font-weight: 700;
        }

        &--btn-add {
            width: 130px;
            height: 44px;
            border-radius: 5px;
            color: white;
            background-color: $purple;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &>svg {
                margin-right: 5px;
            }
        }
    }

    &__filter {
        display: flex;
        flex-direction: row;
        // gap: 10px;
        border-radius: 10px;
        // overflow: hidden;
        width: 400px;
        &--btn {
            width: 100%;
            padding: 5px 10px;
            background-color: rgb(177, 177, 177);
            color: white;
            &.--active {
                background-color: $purple;
                
            }
        }
    }
}