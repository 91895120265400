@import "../../utils/colors.scss";

.settings-page {
    width: 80%;
    margin: 0 auto;
    overflow-y: auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &--name {
            width: 250px;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
    &__username {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        input {
            max-width: 400px;
            padding: 10px 15px;
        }
    }
    &__change-pw {
        margin-top: 10px;
        background-color: $purple;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
    }
}