@import "../../../utils/colors.scss";

.ui-input {
    width: 100%;
    height: 100%;
    background-color: $backgroundMain;
    border-radius: 10px;
    padding: 18px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: rgba($textBlack, 0.7);
    &.--error {
        border: 1px solid red;
    }
    &.--readonly {
        background-color: #e3e3e3 !important;
        cursor: not-allowed;
    }
}