@import "../../utils/colors.scss";
.content-table {
    .ui-table-row {
        grid-template-columns: 2fr 2fr 1fr;
    }
} 
.content-dnd {

    &__item {
        margin-top: 25px;

        &>div {
            margin-top: 0;
        }
        &:hover {
            cursor: grab;
        }
        
        &.--current {
            position: relative;

            &::before {
                border-radius: 5px;
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: 1px dashed black;
                background-color: $backgroundMain;
                z-index: 2;
            }
        }
    }

}
.content-table-row__img {
    height: 100%;
    img {
        height: 4rem;
        width: auto;
    }
}
.content-table-row__name {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    img {
        width: 40px;
        height: auto;
    }
}
.content-table-row__icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    

    &--icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
.concept-table-row--disabled {
    filter: grayscale(1);
    background-color: #eee;
}
