@import "../../../utils/colors.scss";

.drag-n-drop {
    width: 100%;
    height: 100%;
    border: 1px dashed black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    text-align: center;
    &>img {
        width: 15%;
        height: auto;
    }
    &>svg {
        margin-bottom: 20px;
    }
    &>span>span {
        color: #5b93ff;
    }

    &__input {
        display: none;
    }

    &.drag-start {
        background-color: rgba($backgroundMain, 0.7);
        cursor:grab;
    }


    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        &>div {
            padding: 20px;
        }
        &>div>svg {
            margin-bottom: 0px;
        }
        &>span>span {
            color: #5b93ff;
            cursor: pointer;
        }
    }
}