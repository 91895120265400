@import "../../utils/colors.scss";

.orders-page {
    width: 80%;
    margin: 0 auto;
    // min-height: 100%;
    overflow-y: auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            width: 250px;
            font-size: 24px;
            font-weight: 700;
        }
        &-search-input {
            background-color: white;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &-inputs {
            display: flex;
            flex-direction: row;
            gap: 20px;
            div {
                display: flex;
                flex-direction: row;
                height: 44px;
                button {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background-color: white;
                    width: 30px;
                    img {
                        width: 15px;
                        height: auto;
                    }
                }
            }
        }
        &--btn-add {
            width: 130px;
            height: 44px;
            border-radius: 5px;
            color: white;
            background-color: $purple;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &>svg {
                margin-right: 5px;
            }
        }
    }
    &__content {
        width: 100%;
        height: calc(100% - 55px - 80px - 50px);
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        .orders-filter {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-bottom: 20px;
            &-item {
                border-radius: 10px;
                padding: 1px 15px;
                font-size: 14px;
                cursor: pointer;
                &.all {
                    color: white;
                    background-color: black;
                }
                &.success {
                    color: white;
                    background-color: green;
                }
                &.accept {
                    color: white;
                    background-color: $purple;
                }
                &.work {
                    color: white;
                    background-color: orange;
                }
                &.cancelled {
                    color: white;
                    background-color: red;
                }
            }
        }
        .badges-page_pag {
            margin-left: auto;
            margin-right: 0;
            &:last-child {
                margin-top: 10px;
            }
        }
    }
}