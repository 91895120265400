@import "../../utils/colors.scss";

.tpcas-page {
    width: 80%;
    margin: 0 auto;
    overflow-y: auto;
    height: 100%;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    .btn-remove {
        background-color: red;
        padding: 5px 10px;
        border-radius: 10px;
        color: white;
        cursor: pointer;
    }
    .btn-submit {
        color: white;
        background-color:  $purple;
        margin-top: 1em;
        margin-left: auto;
        
        &:hover {
            background-color: $purple;
        }
    }
    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
        &--name {
            // width: 250px;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    &__table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;

        &-row {
            padding: 1em;
            background-color: white;
            font-weight: 500;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1em;
            align-items: center;
        }
    }

    &__edit {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        gap: 1em;

        &--input {
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;
            input {
                border-radius: 10px;
                padding: 5px;
            }
        }
        &--creds {
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;


        }
        .tp-cas_edit>button {
            padding: 10px;
            height: 50px;
        }
        &--creds-block {
            display: flex;
            flex-direction: column;
            gap: 1em;

            
            .creds-block {
                border: 1px solid rgb(199, 199, 199);
                border-radius: 10px;
                padding: 10px;   
                display: flex;
                flex-direction: column;
                gap: 1em;
                &__header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1em;
                    
                    
                    .btn-add {
                        background-color: blue;
                        padding: 5px 10px;
                        border-radius: 10px;
                        color: white;
                        cursor: pointer;
                    }
                    h3 {
                        font-weight: bold;
                    }
                }
              
                &__body {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                }
                &__input {
                    width: 100%;
                    display: flex;
                    gap: 1em;
                    align-items: center;
                    input {
                        width: 100%;
                        max-width: 800px;
                        padding: 5px;
                        border-radius: 10px;
                    }
                }
                &__checkbox {
                    width: 100%;
                    display: flex;
                    gap: 1em;
                    align-items: center;
                    label {
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
    }
   
}