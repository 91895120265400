@import "../../utils/colors.scss";

.input-dish {
    width: 100%;
    max-width: 350px;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &__label {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    &__input {
        background-color: white;
        height: 40px;
        &.error {
            border: 1px solid $red;
        }
    }

    &__dropdown {
        position: absolute;
        background-color: white;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        max-width: 350px;
        left: 0;
        top: 69px;
        overflow-y: auto;
        max-height: 300px;
        z-index: 1000;
        &-item, &-loader {
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            margin: 2px 0;
            padding: 0 17px;
            
        }
        &-loader {
            justify-content: center;
            margin: 2px 0;
            & .promo-loader {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                &>div {
                    width: 15px;
                    height: 15px;
                }
            }
        }
        &-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: #979797;
            border-bottom: 1px solid #F4F4F4;
            cursor: pointer;
         
            &.not-found {
                font-style: italic;
                cursor: default;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}