@import "../src/utils/colors.scss";

// @font-face {
//   font-family: 'Nunito';
//   src: local('Nunito') url("./static/font/Nunito-VariableFont_wght.ttf") format('truetype');
// }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  // font-family: Nunito, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundMain !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  min-width: 100vw !important;
  min-height: 100vh !important;
  width: 100%;
  height: 100%;
  overflow: auto;
}

#root {
  width: 100%;
  // height: 100%;
  min-height: 100%;
  display: flex;
  // font-family: 'Nunito';
}

li {
  list-style-type: none;
}

*:focus-visible {
  outline: none;
}

*:focus {
  outline: -webkit-focus-ring-color auto 0;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba($liteGray, 0.7);
  border-radius: 10px;
}


@media (max-width: 950px) {
  html, body  {
      width: 100vw;
      // overflow-x: hidden;
  }
}