@import "../../utils/colors.scss";

.edit-order {
    width: 80%;
    margin: 0 auto;
    min-height: 100%;
    overflow-y: auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-submit {
            width: 150px !important; 
            background-color: $purple !important;
            border-radius: 10px !important;
            padding: 12px 25px;
            color: white;
            &:hover {
                background-color: $purple;
            }
        }
        &--name {
            // width: 250px;
            font-size: 24px;
            font-weight: 700;
        }
       
    }
    &__content {
        width: 100%;
        // height: calc(100% - 55px - 80px - 50px);
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 60px;
        h1 {
            font-size: 18px;
            font-weight: 700;
        }
        label {
            font-size: 16px;
            font-weight: 500;
        }
        input {
            background-color: white;
            height: 45px;
        }
        &-payment-type {
            max-width: 400px;
            display: flex;
            flex-direction: column;
            label {
                margin-bottom: 10px;
            }

            &--change {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                max-width: 400px;
                gap: 10px;
                align-items: center;
                input {
                    max-width: 100px;
                }
            }
        }
        &-concept {
            display: flex;
            flex-direction: column;
            width: 300px;
        }
        &-client-info {
            width: 100%;
           
            .client-info__inputs {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px;
                
                &>div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    &:first-child {
                        width: 300px;
                    }
                }

            }
        }
        &-delivery-info {
            width: 100%;

            .delivery-info__header {
                font-size: 18px;
                font-weight: 700;
                margin-right: 10px;
                color: gray;
                cursor: pointer;

                &.--active {
                    color: $textBlack;
                }
            }
            .delivery-info__inputs {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                &-terminal {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    label {
                        margin-bottom: 0;
                    }
                    .dropdown {
                        height: 45px;
                   
                    }
                }
                &>div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100px;
                    &:first-child {
                        width: 300px;
                    }

                    &:last-child, &:nth-last-child(2) {
                        width: 300px;
                    }

                }
            }
        }

        &-products-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .products-info__add-product {
                width: 100%;
                &>div {
                    display: flex;
                    flex-direction: column;
                    // padding: 10px 0;
                    .modificator-add {
                        width: 250px;
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        background-color: $purple;
                        border-radius: 10px;
                    }
                   
                    .input-dish {
                        margin-top: 10px;
                        height: auto;
                        flex-direction: column;
                    }
                }
                
            }
            .products-info__list {
                width: 100%;
                min-height: 100px;
                // max-height: 400px;
                // overflow-y: auto;
                background-color: white;
                border-radius: 10px;
                padding: 15px;
                &--header {
                    display: grid;
                    grid-template-columns: .5fr 2fr 2fr 1fr 1fr;
                    font-size: 12px;
                    gap: 20px;
                    color: gray;
                    padding: 5px;
                }
                &-item {
                    display: grid;
                    grid-template-columns: .5fr 2fr 2fr 1fr 1fr;
                    min-height: 50px;
                    gap: 20px;
                    padding: 5px;
                    font-size: 16px;
                    font-weight: 500;
                    &.--total {
                        grid-template-columns: 1fr;
                        font-size: 1.2rem;
                    }
                    &--img {
                        border-radius: 10px;
                        overflow: hidden;
                    }
                    &--amount {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        button {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}


.modal-order-add-product-modifiers {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 150%;
        h1 {
            font-size: 1.2rem;
            margin-bottom: 2rem;
        }
        p {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }
        &-list {
            &>div {
                margin-bottom: .6rem;
            }
        }
    }

    &__btn-add {
        background-color: $purple !important;
        outline: none !important;
        color: white !important;
    }
}