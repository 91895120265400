@import "../../utils/colors.scss";

.order-states {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            font-size: 24px;
            font-weight: 700;
        }

        &--btn-add {
            width: 130px;
            height: 44px;
            border-radius: 5px;
            color: white;
            background-color: $purple;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &>svg {
                margin-right: 5px;
            }
        }
    }
    .dropdown {
        max-width: 300px;
        margin-bottom: 15px;
    }
}