@import "../../../../utils/colors.scss";

.ui-table-row {
    width: 100%;
    min-height: 60px;
    display: grid;
    grid-template-columns: 2fr 4fr 3fr 2fr 1fr;
    background-color: white;
    border-radius: 10px;
    margin-top: 25px;
    user-select: none;
    &__data {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: $textBlack;
        padding: 5px 15px;
        position: relative;

        
    }
}

@media (max-width: 950px) {
    .ui-table-row {
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(auto-fit, 1fr);
       
        &__data {
            justify-content: center;
        }
    }

}