.orders-table {
    .ui-table__header {
        grid-template-columns: 10% 25% 15% 15% 10% 10% 15%;
        div {
            padding: 0 15px;
        }
    }
    & .ui-table-row {
        grid-template-columns:  10% 25% 15% 15% 10% 10% 15%;
        cursor: pointer;
        &__data {
            // justify-content: center;
            div {
                display: flex;
                flex-direction: column;
                span {
                    font-size: .85rem;
                }
                small {
                    font-size: 0.7rem;
                    color: gray;
                }
            }
        }
        
    }
}