@import '../../../utils/colors.scss';

.multi_dropdown {
    position: relative;

	.multi_dropdown-avatar {
		width: 30px;
		height: 30px;
	}
}

.multi_dropdown__button {
	position: relative;
    align-items: center;
	display: grid;
	grid-template-areas: 'text ico';
	grid-template-columns: auto 20px;
	gap: 5px;
	text-align: left;
    padding: 12px 20px;
    padding-right: 10px;
    min-width: 180px;
	width: 100%;
    height: 100%;
    background-color: $backgroundMain;
    border-radius: 10px;
    border: 1px solid rgba($purple, 0.1);
    color: rgba($textBlack, 0.7);
    font-weight: 600;
	font-size: 14px;
	cursor: pointer;
    
    &--text {
        grid-area: text;
        overflow-x: hidden;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.multi-dd-element {
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 60px;
			padding: 5px 10px;
			border-radius: 10px;
			margin: 2px;
			background-color: white;
			&__delete {
				margin: 0 5px;
				width: 14px;
				height: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&__name {
				color: $textBlack;
			}
		}
    }
    &>svg {
        margin-left: 5px;
        color: $textGray;
		grid-area: ico;
    }

	
}

.multi_dropdown__button:focus {
	outline: none;
}


.multi_dropdown__list {
	display: none;

	position: absolute;
	left: 0;
	top: 100%;
	margin: 0;
	padding: 0;
	list-style-type: none;
	max-height: 200px;
	background: #FFFFFF;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	overflow-y: auto;
	border-radius: 10px;
	width: 100%;

	z-index: 1;
	
	&--visible {
		display: block;
	}

	&--icon {
		display: grid;
		grid-template-columns: 50px auto;
		grid-template-areas: 'icon name';
		align-items: center;
		.multi_dropdown-avatar {
			grid-area: icon;
		}
	}
	.dd-list-helper {
		width: 100%;
		height: 5px;
		&-loader {
			width: 100%;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			&--l {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				&>div {
                    width: 15px;
                    height: 15px;
                }
			}
		}
	}
	
}

.multi_dropdown__list--visible {
	display: block;
}

.multi_dropdown__list-item {
	margin: 0;
	padding: 0;
	border-bottom: 0px;
	padding: 14px 20px;
	cursor: pointer;
    color: $liteGray;
    border-bottom: 1px solid #F4F4F4;
}


.multi_dropdown__list-item:last-child {
	border-bottom: 0;
}

.multi_dropdown__list-item:hover {
	background: rgba($liteGray, 0.26);
}
