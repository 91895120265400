.stuff-table {
    .ui-table-row {
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
        cursor: pointer;
    }
} 


.stuff-table-row__icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    

    &--icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
.concept-table-row--disabled {
    filter: grayscale(1);
    background-color: #eee;
}
