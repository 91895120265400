@import '../../utils/colors.scss';

.navbar {
    width: $menuWidth;
    height: auto;
    min-height: 100%;
    max-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow-y: auto;
    &__logo {
        width: 100%;
        // height: 35px;
        height: auto;
        padding: 1em;
        margin-top: 40px;
        // margin-left: 30px;
        margin-bottom: 70px;
        &>svg {
            width: 100%;
            height: 100%;
        }
        &-mobile-close {
            display: none;
        }
    }

    &__list {
        width: 100%;
        padding-left: 12px;
        &-item {
            cursor: pointer;
            width: 100%;
            height: 50px;
            display: grid;
            grid-template-columns: 50px auto;
            justify-items: center;
            align-items: center;
            color: rgba($textBlack, 0.5);
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            &--name {
                justify-self: flex-start;
            }
            svg, path {
                fill: $textBlack;
                opacity: 0.5;
            }
        }
        .active {
            background: linear-gradient(90deg, rgba(172, 169, 255, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);
            color: rgba(172, 169, 255, 0.7);
            svg, path {
                fill: rgb(172, 169, 255);
                opacity: 0.7;
            }            
        }
        
        .dd {
            min-height: 50px;
            height: auto;
            grid-template-rows: 50px auto;
            grid-template-areas: "logo name"
                                 "list list";
            &.open {
                .dd__list {
                    max-height: 500px;
                    transition: max-height 0.015s ease-in;
                    color: $textBlack;
                    .item-link {
                        height: 30px;
                        margin: 5px 0;
                    }
                    .active-link {
                        color: rgb(172, 169, 255);
                    }
                }
            }                     
            &__list {
                justify-self: flex-start;
                grid-area: list;
                padding-left: 50px;
                width: 100%;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.05s ease-out;
                display: flex;
                flex-direction: column;
                background-color: white;
            }
        }
    }
    &__footer {
        width: 100%;
        height: 45px;
        margin-bottom: 20px;
        padding: 0 25px;
        padding-top: 10px;
        display: flex;
        align-items: center;
        margin-top: auto;
        &-avatar {
            width: 45px !important;
            height: 43px !important;
            border-radius: 12px !important;
            margin-right: 10px;
            font-size: 16px !important;
        }
        &-user {
            margin-right: 5px;
            &--name {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
            }
            &--role {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: rgba(0,0,0,0.5);
            }
        }
        &-logout {
            cursor: pointer;
            svg {
                    
                &>path {
                    fill: $liteGray;
                }
            }
        }
    }
}
.mobile-navbar {
    display: none;
}
.mobile-shadow {
    display: none;
}
@media (max-width: 950px) {
    .mobile-shadow {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 9998;
        background-color: rgba(0,0,0,0.5);
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.5s;
        transform: translate(-100%);
        &.mobile-open {
            opacity: 1;
            transform: translate(0%);
        }
    }
    .mobile-navbar {
        display: flex;
        width: 100%;
        height: 50px;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        z-index: 10000;
        justify-content: space-evenly;
        align-items: center;
        border-top: 1px solid rgba($purple, 0.3);

        &__element {
            padding: 0 10px;
            width: 100%;
            .--menu {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.--user {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                .--user-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
            svg {
                    
                &>path {
                    fill: $purple;
                }
            }
        }
    }
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(-100%);
        transition: transform .5s;
        z-index: 9999;
        &__footer {
            display: none;
        }
        &.mobile-open {
            transform: translate(0%);
        }
        &__logo {
            position: relative;
            width: 90%;
            margin-left: 0;
            display: flex;
            justify-content: center;
            &>.ui-icon {
                width: 60%;
            }
            // &-mobile-close {
            //     position: absolute;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     top: -50%;
            //     right: 0;
            //     width: 30px;
            //     height: 30px;
            //     border-radius: 50%;
            //     border: 1px solid rgb(121, 121, 121);
            //     background-color: $purple;
            //     &>svg {
                    
            //         &>path {
            //             fill: white;
            //         }
            //     }
            // }
        }
       
    }
}