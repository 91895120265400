@import "../../utils/colors.scss";

.add-mod-option {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            font-size: 24px;
            font-weight: 700;
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 120px - 67px - 55px - 36px);
        min-height: 450px;
        margin-bottom: 67px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        &-inputs {
            width: 100%;
            height: 100%;
            max-height: 600px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &--left-side {
                width: 200%;
                // display: grid;
                // grid-template-rows: 2fr 3fr 3fr;
                // grid-template-columns: repeat(2, 1fr);
                // grid-template-areas: "name slug"
                //                       "info counts"
                //                       "price weight";
                // grid-gap: 10px;
            }
            &--name, &--slug, &--tech-info, &--min, &--max, &--weight, &--price {
                width: 100%;
                display: flex;
                flex-direction: column;
                grid-area: name;
                margin-bottom: 10px;
                .input-name__label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    margin-bottom: 15px;
                }
                .input-name__input {
                    background-color: white;
                    min-width: 220px;
                    max-width: 380px;
                    width: 50%;
                    height: 42px;
                }
            }
            &--slug {
                grid-area: slug;
            }
            &--weight {
                grid-area: weight;
            }
            &--price {
                grid-area: price;
            }
            &--amount {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                &>div:first-child {
                    margin-bottom: 2rem;
                }
                grid-area: counts;
            }
            &--tech-info {
                grid-area: info;
                width: 100%;
                &>.textarea {
                    width: 100%;
                    height: 160px;
                    resize: none;
                    border-radius: 10px;
                    padding: 9px 15px;
                }
            }
            &--img {
                width: 100%;
                min-height: 200px;
                height: 30%;
                .inputs-img__preview {
                    width: 100%;
                    height: 100%;
                   
                    &-wrapper {
                        width: auto;
                        max-width: 80px;
                        height: auto;
                        margin-bottom: 10px;
                        position: relative;
                    }
                    &-remove {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background-color: rgba($textBlack, 0.5);
                        cursor: pointer;
                        &::before {
                            content: " ";
                            position: absolute;
                            width: 14px;
                            border-bottom: 1px solid $backgroundMain;
                            top: calc(50% - 1px);
                            left: 3px;
                            transform: rotate(45deg);
                        }
                        &::after {
                            content: " ";
                            position: absolute;
                            width: 14px;
                            border-bottom: 1px solid $backgroundMain;
                            top: calc(50% - 1px);
                            left: 3px;
                            transform: rotate(-45deg);
                        }
                    }
        
        
                }
                .inputs-img__error {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    font-weight: 550;
                    font-size: 12px;
                    line-height: 19px;
                    color: rgba($textBlack, 0.7);
                    // width: 300px;
                    &>div {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: $red;
                        margin-right: 10px;
                        
                        &::before {
                            content: " ";
                            width: 10px;
                            border-bottom: 1px solid white;
                            position: absolute;
                            top: calc(50% - 1px);
                            left: calc(50% - 5px);
                        }
            }
                }
            }
        }
    }
    &__submit {
        margin-top: auto;
        align-self: flex-end;
        width: 150px;
        background-color: $purple !important;
        border-radius: 10px;
        padding: 12px 25px;
        color: white;
        &:hover {
            background-color: $purple;
        }
    }
}