@import "../../utils/colors.scss";

.add-stuff-page {
    width: 80%;
    // height: 100%;
    margin: 0 auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            font-size: 24px;
            font-weight: 700;
        }
    }

   &__content {
        width: 100%;
        min-height: 70vh;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 67px;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;

        .tpcas-type {
            // grid-row: 3;
        }
        .content-color {
            display: flex;
            flex-direction: column;
        }
        .add-stuff-page__submit {
            margin-top: auto;
            align-self: flex-end;
            width: 150px;
            background-color: $purple;
            border-radius: 10px;
            padding: 12px 25px;
            color: white;
            &:hover {
                background-color: $purple;
            }
        }
   }
   
}

.add-stuff-page__content-inputs {
    width: 100%;
    // height: 160px;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr;
    grid-template-areas: "name casses no"
                         "scopes scopes no"
                         "code code empty";
    gap: 45px;
    margin-bottom: 50px;
    &>.inputs-block {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        &.--scopes {
            grid-area: scopes;
        }
        &.--casses {
            grid-area: casses;
        }
        &.--code {
            grid-area: code;
            display: grid;
            grid-template-columns: 250px auto;
            grid-template-areas: "label label"
                                 "input btn";
            gap: 10px;
            .code-label {
                grid-area: label;
                margin-bottom: 0;
            }
            .code-input {
                grid-area: input;
                height: 40px;
            }
            .code-btn {
                grid-area: btn;
                height: 40px;
                width: 150px;
                background-color: $purple;
                border-radius: 10px;
                color: white;
                &:hover {
                    background-color: $purple;
                }
                &.--disabled {
                    background-color: gray;
                    cursor: not-allowed;
                }
            }
        }
        label {
            font-size: 16px;
            line-height: 22px;
            color: $textBlack;
            margin-bottom: 10px;
        }
        .add-stuff-input {
            height: 42px;
            margin-bottom: 10px;
        }
        .multi_dropdown__button {
            padding: 5px;
            min-height: 42px;
        }
    }
}

@media (max-width: 1150px) {
    .add-stuff-page__content-inputs {
        grid-template-columns: 1fr 1fr;
    }
}