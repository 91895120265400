@import "../../utils/colors.scss";

.add-content-page {
    width: 80%;
    // height: 100%;
    margin: 0 auto;
    &__header {
        padding-top: 55px;
        margin-bottom: 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--name {
            font-size: 24px;
            font-weight: 700;
        }
    }

   &__content {
        width: 100%;
        min-height: 70vh;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 67px;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;

        .tpcas-type {
            // grid-row: 3;
        }
        .content-color {
            display: flex;
            flex-direction: column;
        }
        .add-content-page__submit {
            margin-top: auto;
            align-self: flex-end;
            width: 150px;
            background-color: $purple;
            border-radius: 10px;
            padding: 12px 25px;
            color: white;
            &:hover {
                background-color: $purple;
            }
        }
   }
   
}

.add-content-page__content-inputs {
    width: 100%;
    // height: 160px;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr;
    gap: 45px;
    margin-bottom: 50px;
    &>.inputs-block {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        label {
            font-size: 16px;
            line-height: 22px;
            color: $textBlack;
            margin-bottom: 10px;
        }
        .add-content-input {
            height: 42px;
            margin-bottom: 10px;
        }
        .add-content-textarea {
            width: 100%;
            height: 127px;
            background-color: $backgroundMain;
            resize: none;
            border-radius: 10px;
            padding: 10px;
        }
    }
    & .dropdown-block {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        &__preview {
            width: 100%;
            height: auto;
           
            &-wrapper {
                width: 250px;
                // height: 80px;
                margin-right: 10px;
                position: relative;
            }
            &-remove {
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                background-color: rgba($textBlack, 0.5);
                cursor: pointer;
                &::before {
                    content: " ";
                    position: absolute;
                    width: 14px;
                    border-bottom: 1px solid $backgroundMain;
                    top: calc(50% - 1px);
                    left: 3px;
                    transform: rotate(45deg);
                }
                &::after {
                    content: " ";
                    position: absolute;
                    width: 14px;
                    border-bottom: 1px solid $backgroundMain;
                    top: calc(50% - 1px);
                    left: 3px;
                    transform: rotate(-45deg);
                }
            }


        }
    }
}

@media (max-width: 1150px) {
    .add-content-page__content-inputs {
        grid-template-columns: 1fr 1fr;

        & .dropdown-block {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            &__preview {
                width: auto;
                height: 7rem;

                &-wrapper {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}