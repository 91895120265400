@import "../../utils/colors.scss";

.change-password {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;
    &__input {
        display: flex;
        flex-direction: column;
        gap: .5em;
        input {
            background-color: white;
            padding: 12px 16px;
            max-width: 300px;
        }
    }
    &__message {
        display: block;
        max-width: 400px;
        color: blue;
    }

    &__submit {
        background-color: $purple;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        margin-right: 10px;
    }
}