@import "./utils/colors.scss";

.content {
    width: calc((100% - #{$menuWidth}));
    min-height: 100%;
    margin-left: $menuWidth;
}

@media (max-width: 950px) {
    .content {
        width: 100%;
        margin: 0;
    }
}

.smart_category__checkboxes {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-type {
        margin-right: 100px;
    }
    &-type, &-status {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $textBlack;
        &>div {
            display: flex;
            align-items: center;
            margin-top: 20px;
        }
        &--label {
            font-size: 14px;
            // margin-left: 15px;
            display: inline-flex;
            align-items: center; 
            user-select: none;
            cursor: pointer;
            &::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 2px;
                margin-right: 0.5em;
            }
        }
        &--input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked+label::before {
                border-color: $purple;
                background-color: $purple;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }
    }
}