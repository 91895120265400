@import "../../utils/colors.scss";

.admin-users-page {
    width: 80%;
    margin: 0 auto;
    overflow-y: auto;
    height: 100%;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    .btn-submit {
        color: white;
        background-color:  $purple;
        margin-top: 1em;
        margin-left: auto;
        
        &:hover {
            background-color: $purple;
        }
    }
    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
        &--name {
            // width: 250px;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    &__table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-bottom: 1em;

        &-row {
            padding: 1em;
            background-color: white;
            font-weight: 500;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1em;
            align-items: center;

            .btn-group {
                display: flex;
                flex-direction: row;
                gap: 1em;

                button {
                    font-size: .8em;
                    color: white;
                    padding: .5em;
                    border-radius: 10px;
                    &.--green {
                        background-color: greenyellow;
                    }
                    &.--red {
                        background-color: red;
                    }
                    &.--admin {
                        background-color: $purple;
                    }
                    &:disabled {
                        filter: grayscale(1);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    &__edit {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        gap: 1em;

        &--input {
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;
            input {
                border-radius: 10px;
                padding: 5px;
                background-color: white;
                max-width: 200px;
            }
        }
        &--checkbox {
            display: flex;
            flex-direction: row;
            gap: .5em;
            align-items: center;
            label {
                cursor: pointer;
            }
        }
    }
   
}

.admin-users-page {
    &__content {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    
    &__header {
        padding-top: 55px;
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
        
        &--name {
            // width: 250px;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
    &__username, &__password {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        input {
            max-width: 400px;
            padding: 10px 15px;
            background-color: white;
        }
    }
    &__change-pw {
        margin-top: 10px;
        background-color: $purple;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
    }
}