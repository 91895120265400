.marketing-triggers {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2em;

    &_actions, &_conditions {
        display: flex;
        flex-direction: column;
        gap: 1em;

        h2 {
            font-size: 1.5rem;
            border-bottom: 1px solid rgba(128, 128, 128, 0.514);
        }

        .dropdown {
            width: 100%;
            max-width: 500px;
        }

        &>div {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }
        .trigger-input {
            display: grid;
            // flex-direction: row;
            grid-template-columns: 100px auto;
            gap: 1em;
            align-items: center;

            sup {
                color: red;
            }
          
            input {
                max-width: 300px;
                padding: 5px 10px;
                border-radius: 10px;
            }
        }
    }
}