@import "../../../utils/colors.scss";

.ui-table {
    width: 100%;
    display: grid;
    
    &__header {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 4fr 3fr 3fr;
        font-size: 12px;
        color: $textBlack;
        opacity: 70%;
    }
    &__content {
        width: 100%;
        display: grid;
        grid-auto-flow: row;
    }
    
}

@media (max-width: 950px) {
    .ui-table {
        &__header {
            grid-template-columns: 1fr !important;
            grid-template-rows: repeat(auto-fit, 1fr);
            font-size: 14px;
           
            &>div {
                background-color: $textGray;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #000;
                padding: 10px;
                color: white;

                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                &:last-child {
                    border: 0;
                }
            }
        }
    }

}