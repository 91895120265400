@import "../../utils/colors.scss";

.modal {
    width: 460px;
    // height: 350px;
    background-color: white;
    margin-top: 166px !important;
    box-shadow: -14px 30px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px !important;
    &-wrapper {
        background: $textBlack !important;
        opacity: 0.3 !important;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        color: $textBlack;
        font-weight: 600px;
        font-size: 20px;
        padding: 30px !important;
        padding-inline-start: 30px !important;
        align-items: center;

        &--done {
            justify-content: flex-end;
        }

        &--btn-close {
            background-color: rgba($red, 0.05);
            color: $red;
            font-size: 14px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__body {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        margin: 0 auto;

        &>svg {
            width: 74px;
            height: 74px;
            color: $red;
            margin-bottom: 14px;
        }
        &-error {
            font-size: .8rem;
            color: $purple;
            cursor: pointer;
            margin-top: 15px;
            &--details {
                margin-top: 5px;
                font-size: .8rem;
            }
        }
    }

    &__footer {
        margin-bottom: 50px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;


        &-btn {
            width: 190px;
            padding: 11px;
            box-shadow: -14px 30px 20px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            

            &.btn-yes {
                border: 1px solid rgba($purple, 0.1);
                color: $purple;
                margin-right: 10px;
            }
            &.btn-no {
                border: 1px solid rgba($purple, 0.1);
                color: $red;
                margin-right: 10px;
            }
        }
    }
}