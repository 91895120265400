@import '../../utils/colors.scss';
.order-listener {
    position: fixed;
    right: 0;
    bottom: 0;

    z-index: 9900000;

    &__block {
        padding: 10px;
        background-color: white;
        border: 1px solid $purple;
        margin: 0 10px 10px 0;
        border-radius: 20px;
        cursor: pointer;

    }
}